import React from 'react';



import StudentJourney from './StudentJourney';







interface StudentJourneyNodeProps {



  data: {



    label: string;



    width: number;



    height: number;



    videoFinished: boolean;



  };



}







const StudentJourneyNode: React.FC<StudentJourneyNodeProps> = ({ data }) => {



  return (



    <div 



      style={{



        width: `${data.width}px`,



        minHeight: `${data.height}px`,



        backgroundColor: 'white',



        borderRadius: '10px',



        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',



        overflow: 'visible',



        fontFamily: '"Roboto", sans-serif',



        color: 'black',



        display: 'flex',



        flexDirection: 'column',



      }}



    >



      <div style={{



        backgroundColor: '#ffde59',



        padding: '10px',



        textAlign: 'center',



      }}>



        <h2 style={{ 



          fontSize: '1.5rem', 



          margin: 0,



          color: '#000000',



          fontWeight: 'bold',



        }}>{data.label}</h2>



      </div>



      <div style={{ flex: 1, padding: '10px' }}>



        <StudentJourney resetOnComplete={true} videoFinished={data.videoFinished} />



      </div>



    </div>



  );



};







export default StudentJourneyNode;








