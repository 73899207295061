import React from 'react';
import { Handle, Position } from 'reactflow';
import noteImage from '../images/note.png';
import 'katex/dist/katex.min.css';
import '../styles/CustomKaTeX.css'; // Make sure to import this
import katex from 'katex';

interface StickyNoteProps {
  data: {
    text: string;
    equation: string;
  };
}

const StickyNote: React.FC<StickyNoteProps> = ({ data }) => {
  const renderEquation = () => {
    const html = katex.renderToString(data.equation, {
      throwOnError: false,
      displayMode: true,
      trust: true,
      macros: {
        '\\text': '\\textbf{\\textsf{#1}}', // Added \textbf for bold text
      },
    });
    return { __html: html };
  };

  return (
    <>
      <Handle type="target" position={Position.Left} style={{ visibility: 'hidden' }} />
      <div
        style={{
          width: '260px', // Increased from 240px
          height: '280px', // Increased from 260px
          backgroundImage: `url(${noteImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          fontSize: '14px',
          color: '#333',
          transform: 'rotate(3deg)',
          fontFamily: '"Patrick Hand", cursive',
          overflow: 'hidden',
        }}
      >
        <p style={{ 
          margin: '0 0 10px 0', 
          textAlign: 'center', 
          maxWidth: '100%', 
          wordWrap: 'break-word',
          fontWeight: 'bold', // Added bold
          fontSize: '16px' // Slightly larger font for the title
        }}>
          {data.text}
        </p>
        <div 
          style={{ marginTop: '15px', maxWidth: '100%', overflow: 'hidden' }} // Add maxWidth and overflow
          dangerouslySetInnerHTML={renderEquation()}
        />
      </div>
      <Handle type="source" position={Position.Right} style={{ visibility: 'hidden' }} />
    </>
  );
};

export default StickyNote;
