import React from 'react';

import { atcb_action } from 'add-to-calendar-button-react';



interface CalendarEventProps {

  data: {

    width: number;

    height: number;

  };

}



const CalendarEvent: React.FC<CalendarEventProps> = ({ data }) => {

  const getNextWednesday = () => {

    const now = new Date();

    const nextWednesday = new Date(now);

    nextWednesday.setDate(now.getDate() + ((3 + 7 - now.getDay()) % 7));

    // If today is Wednesday and the time is past 7:30 PM, move to next Wednesday
    if (now.getDay() === 3 && (now.getHours() > 19 || (now.getHours() === 19 && now.getMinutes() >= 30))) {

      nextWednesday.setDate(nextWednesday.getDate() + 7);

    }

    nextWednesday.setHours(19, 30, 0, 0); // Set to 7:30 PM EST
    return nextWednesday;

  };



  const handleAddToCalendar = () => {

    const startDate = getNextWednesday();
    const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // 1 hour duration

    atcb_action({
      name: "Feeble: Automation Consulting Live Class",
      description: "Join us for a live class on automation consulting.",
      startDate: startDate.toISOString().split('T')[0],
      endDate: startDate.toISOString().split('T')[0], // Same as start date
      startTime: "19:30",
      endTime: "20:30",
      location: "https://event.webinarjam.com/go/live/6/yxr5mbgc0s1sr",
      options: ['Google', 'Apple', 'iCal', 'Microsoft365', 'Outlook.com', 'Yahoo', 'MicrosoftTeams'],
      timeZone: "America/New_York",
      iCalFileName: "Live-Class-Reminder",
    });

  };



  return (

    <div 

      className="flex items-center justify-center text-white rounded-lg cursor-pointer transition-colors duration-300"

      style={{ 

        width: `${data.width}px`, 

        height: `${data.height}px`,

        backgroundColor: '#1b75e5',

        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',

      }}

      onClick={handleAddToCalendar}

    >

      <span className="text-center font-bold">Need a Reminder? Add it to your Calendar</span>

    </div>

  );

};



export default CalendarEvent;



