import React, { useCallback, useState, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import noteBackground from '../images/note.png';
import giphyImage from '../images/giphy.webp';
import proposalImage from '../images/project.meme.png';
import retainImage from '../images/repeat.webp';
import './CustomNode.css';

// Define the props interface for the CustomNode component
interface CustomNodeProps {
  data: {
    id: string; // Add this line to include the id
    label: string; // The label displayed on the node
    details: string; // Additional details for the node
    number: number; // Add this line to include the sequential number
    width: number; // Width of the node
    height: number; // Add this line to include height in the data props
    expanded: boolean; // Add this line to include the expanded state
    isFirstNode: boolean;
    isLastNode: boolean;
    totalHeight: number; // Add this to pass the total height of both rows
    rowIndex: number; // Add this to know which row the node is in
    onExpand: (id: string, expanded: boolean) => void;
  };
}

// CustomNode component represents a single node in the flow diagram
const CustomNode: React.FC<CustomNodeProps> = ({ data }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    if (data.onExpand) {
      data.onExpand(data.id, !data.expanded);
    }
  }, [data]);

  const renderExpandedContent = () => {
    const defaultImageHeight = data.height * 0.5625; // 75% of previous size
    const retainImageHeight = data.height * 0.421875; // Further reduced by 25% (0.5625 * 0.75)
    const contentStyle = {
      height: `${data.totalHeight - 40}px`,
      display: 'flex',
      flexDirection: 'column' as const,
      paddingTop: '20px',
    };
    const getImageStyle = (height: number) => ({
      maxHeight: `${height}px`,
      width: 'auto',
      maxWidth: '100%',
      objectFit: 'contain' as const,
      margin: '0 auto 10px',
    });
    const textStyle = {
      flex: 1,
      overflowY: 'auto' as const,
      padding: '10px',
    };

    const renderMarkdown = (content: string) => (
      <ReactMarkdown 
        className="markdown-content"
        remarkPlugins={[remarkGfm]}
      >
        {content}
      </ReactMarkdown>
    );

    if (data.label === "Technical Due Diligence") {
      return (
        <div style={contentStyle}>
          <img src={giphyImage} alt="Technical Due Diligence" style={getImageStyle(defaultImageHeight)} />
          <div style={textStyle}>
            {renderMarkdown(data.details)}
          </div>
        </div>
      );
    } else if (data.label === "Send a Proposal") {
      return (
        <div style={contentStyle}>
          <img src={proposalImage} alt="Send a Proposal" style={getImageStyle(defaultImageHeight)} />
          <div style={textStyle}>
            {renderMarkdown(data.details)}
          </div>
        </div>
      );
    } else if (data.label === "Retain") {
      return (
        <div style={contentStyle}>
          <img src={retainImage} alt="Retain" style={getImageStyle(retainImageHeight)} />
          <div style={textStyle}>
            {renderMarkdown(data.details)}
          </div>
        </div>
      );
    } else {
      return (
        <div style={contentStyle}>
          <div style={textStyle}>
            {renderMarkdown(data.details)}
          </div>
        </div>
      );
    }
  };

  const getNodeStyle = () => {
    const isMobile = windowWidth < 768;
    const baseStyle = {
      width: `${data.width}px`,
      height: `${data.height}px`,
      backgroundImage: `url(${noteBackground})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      transition: 'all 0.3s ease-in-out',
      position: 'relative' as const,
    };

    if (data.expanded) {
      let expandedWidth;
      let expandedHeight;
      let marginLeft = '0';
      let marginTop = '0';

      if (isMobile) {
        expandedWidth = Math.min(windowWidth, data.width * 3 + 100);
        expandedHeight = `${data.totalHeight}px`;
        marginLeft = `-${(expandedWidth - data.width) / 2}px`;
        marginTop = data.rowIndex === 0 ? '0' : `-${data.height / 2}px`;
      } else {
        expandedWidth = data.width * 3 + 100;
        expandedHeight = `${data.totalHeight - 75}px`;
        marginLeft = data.isFirstNode ? '0' : 
                     data.isLastNode ? `-${data.width * 2 + 100}px` : 
                     `-${data.width + 50}px`;
        marginTop = data.rowIndex === 0 ? '0' : `-${data.totalHeight / 2}px`;
      }

      const expandedStyle = {
        width: `${expandedWidth}px`,
        height: expandedHeight,
        marginLeft,
        marginTop,
        position: 'absolute' as const,
        top: 0,
        left: 0,
      };

      return { ...baseStyle, ...expandedStyle };
    }

    return baseStyle;
  };

  return (
    <div
      className={`rounded-lg shadow-lg overflow-hidden cursor-pointer relative ${data.expanded ? 'expanded' : ''}`}
      style={getNodeStyle()}
      onClick={handleClick}
    >
      <Handle type="target" position={Position.Top} />
      {!data.expanded && (
        <div className="absolute inset-0 flex flex-col items-center justify-center p-4">
          <div className="bg-blue-500 text-white rounded-full w-10 h-10 flex items-center justify-center text-xl font-bold mb-2">
            {data.number}
          </div>
          <h3 className="text-3xl font-bold text-center text-blue-500">{data.label}</h3>
        </div>
      )}
      {data.expanded && (
        <div>
          {renderExpandedContent()}
        </div>
      )}
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default CustomNode;
