import React, { useEffect, useState, useMemo, useCallback } from 'react';































import clippyImage from '../images/Joshy-Clippy.png'; // Ensure this path is correct































import '../styles/ClippyAssistant.css'; // Import the CSS file for styling































































interface MessageMap {































  [key: string]: string;































}































































interface ClippyAssistantProps {































  videoFinished: boolean;































  expandedCard: string | null;































}































































const ClippyAssistant: React.FC<ClippyAssistantProps> = ({ videoFinished, expandedCard }) => {































    const [currentMessage, setCurrentMessage] = useState('');































    const [animationState, setAnimationState] = useState<'idle' | 'zoomIn' | 'zoomOut'>('idle');
    const [isCardExpanded, setIsCardExpanded] = useState(false);































































    const defaultMessages = useMemo(() => [































        "Howdy sailor. It's Josh! I'm not sure if I've mentioned this on my socials, but in a past life, I was Clippy from Microsoft 04' ",































        "Click on each card to learn my secret sauce",































        "People always ask me how to close $8,000 deals",































        "and if you're also interested in my playbook....",































        "Come to the live class I'm teaching this week",































        "I promise I won't hold back. Plus I'm doing a live Q&A!"































    ], []);































































    const boxMessages: MessageMap = useMemo(() => ({































        "Prospecting": "Lead magnets work great too. Everyone loves a sexy Miro mind map… Or maybe that's just me…",































        "Sales Call": "Contextualize. Identify the core drivers of their business. Ideate collaboratively. Scope. Gauge. Re-scope. And BOOK THAT REVENUE!!!",































        "Technical Due Diligence": "This process usually doesn't take very long, but doing a good job here will make your life so much easier once you dive in and start building.",































        "Send a Proposal": "I believe it was Lil Wayne who once said, \"that first month's retainer feels good, but that 6 month renewal feels great.\" …Or maybe I just thought that in my head as I was rapping along to The Carter IV in the shower last week. Either way, the point stands.",































        "Fulfillment": "Remember, it's just logic. Businesses are a series of inputs, systems, and outputs. Map it all out, build, deliver, then iterate. That's a recipe for a happy client.",































        "Retain": "Clients get excited when we do great work. Like a moth to a flame, they always find their way back. So don't overthink this step and just focus on creating value."































    }), []);































































    const cycleMessages = useCallback(() => {







        setCurrentMessage(prevMessage => {







            const currentIndex = defaultMessages.indexOf(prevMessage);







            const nextIndex = (currentIndex + 1) % defaultMessages.length;







            return defaultMessages[nextIndex];







        });







    }, [defaultMessages]);































































    useEffect(() => {







        setCurrentMessage(defaultMessages[0]);







    }, [defaultMessages]);































































    useEffect(() => {







        let intervalId: NodeJS.Timeout;
        if (videoFinished && !isCardExpanded) {
            intervalId = setInterval(cycleMessages, 8000);
        }
        return () => clearInterval(intervalId);







    }, [videoFinished, cycleMessages, isCardExpanded]);































































    useEffect(() => {







        if (expandedCard) {







            setCurrentMessage(boxMessages[expandedCard]);







            setIsCardExpanded(true);







        } else {
            setIsCardExpanded(false);
            if (videoFinished) {
                cycleMessages();
            }
        }







    }, [expandedCard, boxMessages, cycleMessages, videoFinished]);































































    useEffect(() => {







        if (videoFinished) {







            setAnimationState('zoomIn');







            setTimeout(() => {







                setAnimationState('zoomOut');







                setTimeout(() => {







                    setAnimationState('idle');







                }, 1000);







            }, 1000);







        }







    }, [videoFinished]);































































    return (































        <div className={`clippy-assistant ${animationState}`}>































            <div className="clippy-content">































                <div className="bubble" style={{ opacity: 1 }}>































                    {currentMessage}































                </div>































                <img 































                    src={clippyImage} 































                    alt="Clippy Assistant" 































                    className="clippy-image"































                />































            </div>































        </div>































    );































};































































export default ClippyAssistant;














































































