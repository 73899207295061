import React, { useState, useCallback } from 'react';















import FlowDiagram from '../components/FlowDiagram';















import ClippyAssistant from '../components/ClippyAssistant';























import BackdropOverlay from '../components/BackdropOverlay';























import WistiaModal from '../components/WistiaModal';































const HomePage: React.FC = () => {















  const [videoFinished, setVideoFinished] = useState(false);















  const [expandedCard, setExpandedCard] = useState<string | null>(null);















  const handleVideoComplete = useCallback(() => {



    setVideoFinished(true);



  }, []);































  const handleCardExpand = useCallback((cardLabel: string | null) => {















    setExpandedCard(cardLabel);















  }, []);































  return (















    <div className="relative">















      {/* Main content - always rendered but covered by backdrop when video is not finished */}

      <div className={`${!videoFinished ? 'pointer-events-none' : ''}`}>

        <FlowDiagram 

          videoFinished={videoFinished} 

          onCardExpand={handleCardExpand}

        />

        <ClippyAssistant

          videoFinished={videoFinished}

          expandedCard={expandedCard}

        />

      </div>



      {/* Backdrop and video - only visible when video is not finished */}

      {!videoFinished && (

        <>

          <BackdropOverlay />

          <WistiaModal onVideoComplete={handleVideoComplete} />

        </>

      )}

    </div>

  );

};



export default HomePage;






































