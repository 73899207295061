import React from 'react';

interface ImageDisplayProps {
  imageSrc: string;
  altText: string;
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({ imageSrc, altText }) => {
  return (
    <div className="image-display">
      <img src={imageSrc} alt={altText} className="max-w-full h-auto" />
    </div>
  );
};

export default ImageDisplay;