import React, { useEffect } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import { stripePayouts } from '../utils/studentJourneyData';



interface ScreenshotCarouselProps {

  currentIndex: number;

  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;

}



const ScreenshotCarousel: React.FC<ScreenshotCarouselProps> = ({ currentIndex, setCurrentIndex }) => {

  const images = [

    require('../images/1.png'),

    require('../images/2.png'),

    require('../images/3.png'),

    require('../images/4.png'),

    require('../images/5.png'),

    require('../images/6.png'),

    require('../images/7.png'),

    require('../images/8.png'),

  ];



  useEffect(() => {

    let interval: NodeJS.Timeout;

    if (currentIndex !== -1) {

      interval = setInterval(() => {

        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);

      }, 10000); // Change every 10 seconds

    }

    return () => clearInterval(interval);

  }, [currentIndex, images.length, setCurrentIndex]);



  return (

    <div className="relative w-full" style={{ minHeight: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

      {currentIndex === -1 ? (

        <div style={{ textAlign: 'center', width: '100%' }}>

          <p style={{ 
            fontSize: '18px', 
            fontWeight: 'bold', 
            color: '#000000', 
            margin: 0, 
            padding: 0, 
            fontFamily: '"Roboto", sans-serif', 
            textRendering: 'geometricPrecision', 
            lineHeight: '1.4' 
          }}>
            Click anywhere to<br />watch Claire's journey!
          </p>

        </div>

      ) : (

        <AnimatePresence mode="wait">

          <motion.div 
            key={currentIndex} 
            initial={{ opacity: 0 }} 
            animate={{ opacity: 1 }} 
            exit={{ opacity: 0 }} 
            transition={{ duration: 0.5 }}
            style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
          >

            <img 
              src={images[currentIndex]} 
              alt={`Stripe payout ${currentIndex + 1}`} 
              style={{ width: '100%', height: 'auto', objectFit: 'contain', marginBottom: '10px' }} 
            />

            {currentIndex >= 0 && currentIndex < stripePayouts.length && (
              <div style={{ textAlign: 'center', width: '100%' }}>
                <p style={{ fontWeight: 'bold', margin: '5px 0' }}>{stripePayouts[currentIndex].acquisitionChannel}</p>
                <p style={{ fontWeight: 'bold', margin: '5px 0' }}>{stripePayouts[currentIndex].automation}</p>
              </div>
            )}

          </motion.div>

        </AnimatePresence>

      )}

    </div>

  );

};



export default ScreenshotCarousel;



