import React from 'react';
import { useNavigate } from 'react-router-dom';

interface PlugAndPlayButtonProps {
  data: {
    width: number;
    height: number;
  };
}

const PlugAndPlayButton: React.FC<PlugAndPlayButtonProps> = ({ data }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/resources');
  };

  return (
    <div 
      className="flex items-center justify-center text-white rounded-lg cursor-pointer hover:bg-blue-600 transition-colors duration-300"
      style={{ 
        width: `${data.width}px`, 
        height: `${data.height}px`,
        backgroundColor: '#1b75e5', 
        boxShadow: '0 10px 6px rgba(0, 100, 255, 0.25), 0 10px 7px rgba(0, 100, 255, 0.1)'
      }}
      onClick={handleClick}
    >
      <span className="text-center font-bold">Take me to my Plug and Play!</span>
    </div>
  );
};

export default PlugAndPlayButton;
