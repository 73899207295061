import React, { useState, useEffect, useCallback } from 'react';































































































































import ProfilePicture from './ProfilePicture';































































































































import EarningsCounter from './EarningsCounter';































































































































import ScreenshotCarousel from './ScreenshotCarousel';































































































































































































































































interface StudentJourneyProps {































































































































  resetOnComplete?: boolean;































































































































  videoFinished: boolean;































































































































}































































































































































































































































const StudentJourney: React.FC<StudentJourneyProps> = ({ resetOnComplete = false, videoFinished }) => {































































































































  const [currentIndex, setCurrentIndex] = useState(-1);































































































































  const [isAnimating, setIsAnimating] = useState(false);































































































































  const [hasClicked, setHasClicked] = useState(false);































































































































































































































































  const startAnimation = useCallback(() => {































































































































    if (videoFinished && !hasClicked) {































































































































      setIsAnimating(true);































































































































      setCurrentIndex(0);































































































































      setHasClicked(true);































































































































    }































































































































  }, [videoFinished, hasClicked]);































































































































































































































































  useEffect(() => {































































































































    if (isAnimating) {































































































































      const timer = setInterval(() => {































































































































        setCurrentIndex((prevIndex) => {































































































































          if (prevIndex >= 7) {































































































































            setIsAnimating(false);































































































































            clearInterval(timer);































































































































            if (resetOnComplete) {































































































































              return -1;































































































































            }































































































































            return 7;































































































































          }































































































































          return prevIndex + 1;































































































































        });































































































































      }, 10000);































































































































      return () => clearInterval(timer);































































































































    }































































































































  }, [isAnimating, resetOnComplete]);































































































































































































































































  useEffect(() => {































































































































    if (currentIndex === -1 && hasClicked && resetOnComplete) {































































































































      const restartTimer = setTimeout(() => {































































































































        setIsAnimating(true);































































































































        setCurrentIndex(0);































































































































      }, 2000);































































































































      return () => clearTimeout(restartTimer);































































































































    }































































































































  }, [currentIndex, hasClicked, resetOnComplete]);































































































































































































































































  useEffect(() => {































































































































    const handleClick = () => {































































































































      startAnimation();































































































































    };































































































































    document.addEventListener('click', handleClick);































































































































    return () => document.removeEventListener('click', handleClick);































































































































  }, [startAnimation]);































































































































































































































































  return (



    <div



      style={{



        width: '100%',



        display: 'flex',



        backgroundColor: 'white',



        padding: '0.5rem', // Reduced padding



        gap: '0.5rem', // Reduced gap



        fontFamily: '"Roboto", sans-serif',



        color: 'black',



        fontSize: '16px',



      }}



      onClick={startAnimation}



    >



      {/* Left Column */}



      <div style={{ 



        flex: 1,



        display: 'flex', 



        flexDirection: 'column', 



        alignItems: 'center',



        gap: '0.75rem', // Increased gap between photo and text



      }}>



        <div style={{ 



          width: '120px',



          height: '120px',



          position: 'relative',



          overflow: 'hidden',



          borderRadius: '50%',



          border: '4px solid #ffde59',



          marginBottom: '1.55rem', // Added small margin to the bottom of the photo



        }}>



          <ProfilePicture />



        </div>



        <p style={{ 



          fontSize: '14px', 



          color: '#000000', 



          textAlign: 'center', 



          maxWidth: '90%',



          lineHeight: '1.3', // Slightly reduced line height



          margin: 0,



          letterSpacing: '0.5px'



        }}>



          Claire started her agency in October '23 following Feeble Automation Academy guidance she secured these projects within 3 months part time!



        </p>



      </div>







      {/* Right Column */}



      <div style={{ 



        flex: 1,



        display: 'flex', 



        flexDirection: 'column', 



        alignItems: 'center',



        gap: '0.5rem', // Reduced gap



      }}>



        <h3 style={{ 



          fontSize: '18px', 



          fontWeight: 'bold', 



          color: '#000000',



          marginBottom: '0.25rem', // Reduced margin



        }}>



          Revenue Generated



        </h3>



        <EarningsCounter currentIndex={currentIndex} isAnimating={isAnimating} />



        <ScreenshotCarousel currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />



      </div>



    </div>



  );



};







export default StudentJourney;








