import React, { useEffect, useState } from 'react';
import { motion, useMotionValue, useTransform, animate } from 'framer-motion';

interface EarningsCounterProps {
  currentIndex: number;
  isAnimating: boolean;
}

const cumulativeEarnings = [
  726.01,
  1818.08,
  3269.41,
  4725.61,
  6418.06,
  8109.43,
  9800.48,
  10646.01
];

const EarningsCounter: React.FC<EarningsCounterProps> = ({ currentIndex, isAnimating }) => {
  const count = useMotionValue(0);
  const rounded = useTransform(count, latest => Math.round(latest * 100) / 100);
  const [displayedEarnings, setDisplayedEarnings] = useState(0);

  useEffect(() => {
    if (isAnimating && currentIndex >= 0 && currentIndex < cumulativeEarnings.length) {
      const targetEarnings = cumulativeEarnings[currentIndex];
      const animation = animate(count, targetEarnings, { duration: 2 });
      return animation.stop;
    } else {
      setDisplayedEarnings(0);
    }
  }, [count, currentIndex, isAnimating]);

  useEffect(() => {
    const unsubscribe = rounded.on("change", setDisplayedEarnings);
    return unsubscribe;
  }, [rounded]);

  const formattedEarnings = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(displayedEarnings);

  return (
    <motion.div style={{
      fontSize: '2.25rem', // text-4xl
      fontWeight: 'bold',
      color: '#22c55e', // text-green-500
      textAlign: 'center',
      width: '100%'
    }}>
      {formattedEarnings}
    </motion.div>
  );
};

export default EarningsCounter;