export interface StripePayoutData {
  amount: number;
  acquisitionChannel: string;
  automation: string;
}

export const stripePayouts: StripePayoutData[] = [
  {
    amount: 726.01,
    acquisitionChannel: "LinkedIn",
    automation: "Client onboarding automation"
  },
  {
    amount: 1092.07,
    acquisitionChannel: "TikTok",
    automation: "CRM integration and data migration"
  },
  {
    amount: 1451.33,
    acquisitionChannel: "Twitter",
    automation: "Data migration and AirTable inteface build"
  },
  {
    amount: 1456.20,
    acquisitionChannel: "Personal Network",
    automation: "Generate daily content with 0 user input"
  },
  {
    amount: 1692.45,
    acquisitionChannel: "Upwork",
    automation: "Marketing automation setup"
  },
  {
    amount: 1691.37,
    acquisitionChannel: "LinkedIn",
    automation: "Onboarding user workflow for Telegram community"
  },
  {
    amount: 1691.05,
    acquisitionChannel: "Twitter",
    automation: "AI-powered chatbot implementation"
  },
  {
    amount: 845.53,
    acquisitionChannel: "Personal Network",
    automation: "Gather tasks from various productivity apps"
  }
];

export const totalEarnings = stripePayouts.reduce((sum, payout) => sum + payout.amount, 0);