import { Node, Edge, MarkerType } from 'reactflow';

// Constants for node sizing and positioning
const maxWidth = 600; // Maximum width for the first node
const widthDecreasePercentage = 0.1; // 10% decrease in width for each subsequent node
const centerX = 400; // Horizontal center position for nodes
const verticalSpacing = 200; // Increased vertical spacing between nodes

// Define the nodes for the customer journey
export const nodes: Node[] = [
  {
    id: '1',
    type: 'customNode',
    data: { 
      label: 'Prospecting', 
      width: maxWidth,
      expanded: false,
      details: `### Clients Are Everywhere

Literally. We can create value by building no-code workflows for practically any business, ranging from SMBs to enterprises.

### It Starts with Prospecting

Reaching out to business owners and getting them on calls is the first step.

We'll delve into this in depth during the live class, but the best way to start setting up calls—whether your client acquisition channel is freelance platforms, white labeling, cross-selling, or any of the dozen other places we source clients—is by:

- Demonstrating a capacity to **think critically** about the specific drivers of their business.
- Making it clear that you've spent time **brainstorming ways** to make their organization more robust.

If you do this effectively, they **will** hear you out.

### Closing the Deal

Once we have those calls on our calendar, it's **like fish in a barrel.**`,
      type: 'withoutImage',
      hideImageOnExpand: true,
      isFirstNode: true,
      isLastNode: false,
      number: 1,
    },
    position: { x: centerX - maxWidth / 2, y: 0 },
    draggable: false,
  },
  {
    id: '2',
    type: 'customNode',
    data: { 
      label: 'Sales Call', 
      width: maxWidth * (1 - widthDecreasePercentage * 1),
      expanded: false,
      details: `### Avoid Broad Terms

In a world where we can build practically anything—and replicate almost any process that a human does regularly with automation—**it's easy to fall into the trap** of speaking in very broad terms; but that leaves room for indecision.

### Showcase Your Portfolio

In order to cut right through a business owner's hesitation on a sales call, I like to **show off a portfolio project** as soon as I can work it into the flow of our conversation.

### Key to Successful Sales Calls

We'll deep dive into **retainers vs projects**, **scoping**, **pricing**, and more in plenty of nuance during the live class. But in the meantime, when it comes to sales calls, here's the most important thing to hold true:

#### If we do a good job scoping a build, and the value proposition is very clear, the deal will sell itself.`,
    type: 'withoutImage',
      hideImageOnExpand: true,
      isFirstNode: false,
      isLastNode: false,
      number: 2, // Add this line
    },
    position: { x: centerX - (maxWidth * (1 - widthDecreasePercentage * 1)) / 2, y: verticalSpacing * 1.25 }, // Centered position
    draggable: false,
  },
  {
    id: '3',
    type: 'customNode',
    position: { x: centerX - (maxWidth * (1 - widthDecreasePercentage * 2)) / 2, y: verticalSpacing * 2 },
    data: { 
      label: 'Technical Due Diligence', 
      image: require('../images/giphy.webp'),
      width: maxWidth * (1 - widthDecreasePercentage * 2),
      details: `### Confirm Technical Feasibility

Once we've scoped a project—either in real time on a sales call or later that day—ensure that there aren't any technical roadblocks that would prevent you from building out the project.

### It's Easier Than It Sounds

This isn't as hard as it sounds. It's usually just:

- Checking up on a couple of **API endpoints**.
- Maybe building out a **flowchart**, if you're feeling fancy.

### Let's Dive Deeper in Class

#### Remind me to circle back to this during the class in case I forget, and we can drill down on the specifics.`,

      expanded: false,
      type: 'withImage',
      hideImageOnExpand: true,
      isFirstNode: false,
      isLastNode: false,
      number: 3, // Add this line
    },
    draggable: false,
  },
  {
    id: '4',
    type: 'customNode',
    position: { 
      x: centerX - (maxWidth * (1 - widthDecreasePercentage * 3)) / 2, 
      y: verticalSpacing * 3 
    },
    data: { 
      label: 'Send a Proposal', 
      image: require('../images/project.meme.png'),
      width: maxWidth * (1 - widthDecreasePercentage * 3),
      details: `### Retainers Over Projects
  
  **Retainers > Projects**. And there are a couple of reasons I feel this way, but the main one is **scope creep**.
  
  ### Finalizing the Build
  
  Once we've settled on what to build in tandem with the client and performed the technical due diligence to ensure there are no red flags, the last step of our sales cycle is to fire off a **retainer or project framework**.
  ### Life Is Easier on Retainer

  Life is easy on retainer, but sometimes a client will insist on kicking off on a project basis. That's not the end of the world—it just means we have to be **more specific with the scope of work**.
  
  ### Setting Clear Boundaries
  
  The proposal is where we set clear boundaries and expectations as it relates to the build-out. Just like in the tech DD phase, doing a good job here could save you some headaches down the line when the client gets excited and starts asking for a dozen new features on top of the main workflow.`,
  
      expanded: false,
      type: 'withImage',
      hideImageOnExpand: true,
      isFirstNode: false,
      isLastNode: false,
      number: 4,
    },
    draggable: false,
  },
  {
    id: '5',
    type: 'customNode',
    position: { x: centerX - (maxWidth * (1 - widthDecreasePercentage * 4)) / 2, y: verticalSpacing * 4 },
    data: { 
      label: 'Fulfillment', 
      image: require('../images/feeble.png'),
      width: maxWidth * (1 - widthDecreasePercentage * 4),
      details: `### Time to Build

Now comes the fun part. Pour a big cup of coffee, grab your headphones, throw your phone on DnD, and start building.

### Or Outsource Fulfillment

Or don't do any of that and just **outsource the fulfillment**. Both routes are totally viable. Remember, there's no rule that says you have to build the automations yourself.

### Embrace Flexibility

There's something addictive about seeing an idea come to life, and I personally love getting my hands dirty. But I also don't want there to be any confusion on this point: if you're not interested in learning how to build with no-code, that doesn't mean you can't make money doing automations, AI, or workflow consulting.

### Join the Live Class

If you come to the live class this week, you'll hear me flesh out this line of thinking. Ultimately, it all comes down to **understanding the fundamentals**.`,

    expanded: false,
      type: 'withImage',
      hideImageOnExpand: true,
      isFirstNode: false,
      isLastNode: false,
      number: 5,
    },
    draggable: false,
  },
  {
    id: '6',
    type: 'customNode',
    position: { 
      x: centerX - (maxWidth * (1 - widthDecreasePercentage * 5)) / 2, 
      y: verticalSpacing * 5 
    },
    data: { 
      label: 'Retain', 
      image: require('../images/repeat.webp'),
      width: maxWidth * (1 - widthDecreasePercentage * 5),
      details: `### Fewer Clients, More Value
  
  The best part about this business model is that we don't need a ton of clients. A couple of **$5k retainers** and we're in business—six figures of business, in fact, presuming we retain our clients.
  
  ### Focus on Existing Clients
  
  Remember, we'll talk more about finding initial deals during the class, but the greatest driver of revenue for me has come—not from new clients—but **existing ones**.
  
  ### Always Be Scoping
  
  A business with a positive growth rate and high CLV will almost always be faced with new problems for us to solve. Those problems might be related to the initial reasons we started working with a client or a whole new vertical of their business.
  
  - For this reason, we should always be **scoping**, even when we're actively working on a project.
  - Keep your eyes open for new opportunities.
  
  ### Build Structural Systems
  
  And keep in mind, when we do good work and build systems that are **structural** to the way a business operates, that in itself will motivate the clients to keep us around.`,
  
      expanded: false,
  
      type: 'withImage',
      hideImageOnExpand: true,
      isFirstNode: false,
      isLastNode: true,
      number: 6,
    },
    draggable: false,
  },
];

// Define the edges connecting the nodes
export const edges: Edge[] = [
  { id: 'e1-2', source: '1', target: '2', type: 'smoothstep', animated: true, style: { stroke: '#1b75e5', strokeWidth: 2 }, markerEnd: { type: MarkerType.ArrowClosed, color: '#1b75e5' } },
  { id: 'e2-3', source: '2', target: '3', type: 'smoothstep', animated: true, style: { stroke: '#1b75e5', strokeWidth: 2 }, markerEnd: { type: MarkerType.ArrowClosed, color: '#1b75e5' } },
  { id: 'e3-4', source: '3', target: '4', type: 'smoothstep', animated: true, style: { stroke: '#1b75e5', strokeWidth: 2 }, markerEnd: { type: MarkerType.ArrowClosed, color: '#1b75e5' } },
  { id: 'e4-5', source: '4', target: '5', type: 'smoothstep', animated: true, style: { stroke: '#1b75e5', strokeWidth: 2 }, markerEnd: { type: MarkerType.ArrowClosed, color: '#1b75e5' } },
  { id: 'e5-6', source: '5', target: '6', type: 'smoothstep', animated: true, style: { stroke: '#1b75e5', strokeWidth: 2 }, markerEnd: { type: MarkerType.ArrowClosed, color: '#1b75e5' } },
];

