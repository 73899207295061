import React, { useCallback, useEffect, useState, useMemo } from 'react';
import ReactFlow, { 
  Controls, 
  Node, 
  useNodesState, 
  useEdgesState, 
  ReactFlowProvider,
  useReactFlow, 
  Viewport, 
  NodeTypes,
  EdgeTypes,
  getBezierPath,
  EdgeProps, 
  OnEdgesChange,
  NodeProps
} from 'reactflow';
import 'reactflow/dist/style.css';
import CustomNode from './CustomNode';
import { nodes as initialNodes, edges as initialEdges } from '../utils/flowData';
import StudentJourneyNode from './StudentJourneyNode';
import StickyNote from './StickyNote';
import StudentSuccess from './StudentSuccess';
import PlugAndPlayButton from './PlugAndPlayButton';
import 'katex/dist/katex.min.css';
import WebinarButton from './WebinarButton';
import WebinarCounter from './WebinarCounter';
import CalloutMessage from './CalloutMessage';
import CalendarEvent from './CalendarEvent';

// Custom edge component
const CustomEdge: React.FC<EdgeProps> = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, data }) => {
  const [edgePath] = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });

  return (
    <path
      id={id}
      style={{
        ...style,
        strokeWidth: 4,
        stroke: '#1b75e5',
        animation: 'flowAnimation 8s linear infinite',
      }}
      className="react-flow__edge-path"
      d={edgePath}
    />
  );
};

interface FlowDiagramContentProps {
  onCardExpand: (cardLabel: string | null) => void;
  videoFinished: boolean;
}

const FlowDiagramContent: React.FC<FlowDiagramContentProps> = ({ onCardExpand, videoFinished }) => {
  const { fitView, setViewport, getNodes } = useReactFlow();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const nodeTypes = useMemo(() => ({
    customNode: CustomNode,
    studentJourney: StudentJourneyNode,
    studentSuccess: StudentSuccess,
    plugAndPlayButton: PlugAndPlayButton,
    stickyNote: StickyNote,
    webinarButton: WebinarButton as unknown as React.ComponentType<NodeProps>,
    webinarCounter: WebinarCounter as unknown as React.ComponentType<NodeProps>,
    calloutMessage: CalloutMessage as unknown as React.ComponentType<NodeProps>,
    calendarEvent: CalendarEvent as unknown as React.ComponentType<NodeProps>,
  }), []);

  const edgeTypes = useMemo(() => ({ custom: CustomEdge }), []);

  const stickyNoteText = "Winner's Formula";
  const equationLatex = "\\text{Leads required} = \\\\[0.3em] \\frac{\\text{Target Revenue}}{\\text{Deal Size} \\times \\text{Conversion Rate}}";

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const calculateNodePositions = useCallback(() => {
    const isMobile = windowWidth < 768;
    const centerX = windowWidth / 2;
    const maxWidth = 300;
    const aspectRatio = 2 / 3;
    const height = maxWidth / aspectRatio;
    const horizontalSpacing = isMobile ? 20 : 50;
    const verticalSpacing = isMobile ? 40 : 80;
    const totalHeight = (height + verticalSpacing) * 2;

    const calculatedNodes = initialNodes.map((node, index) => {
      const row = Math.floor(index / 3);
      const col = index % 3;
      const position = {
        x: centerX + (col - 1) * (maxWidth + horizontalSpacing) - maxWidth / 2,
        y: row * (height + verticalSpacing) + 50
      };
      return {
        ...node,
        position,
        data: {
          ...node.data,
          width: maxWidth,
          height: height,
          isFirstNode: col === 0,
          isLastNode: col === 2,
          totalHeight: totalHeight,
          rowIndex: row
        }
      };
    });

    const studentJourneyWidth = maxWidth * 1.5;
    const studentJourneyHeight = height * 0.75;
    const firstCard = calculatedNodes[0];
    const studentJourneyPosition = {
      x: firstCard.position.x - studentJourneyWidth - horizontalSpacing,
      y: firstCard.position.y + height / 2
    };

    const studentSuccessPosition = {
      x: studentJourneyPosition.x,
      y: studentJourneyPosition.y + studentJourneyHeight + verticalSpacing*2
    };

    const lastCardInFirstRow = calculatedNodes[2];
    const stickyNoteHeight = 280;
    const stickyNotePosition = {
      x: lastCardInFirstRow.position.x + maxWidth + horizontalSpacing,
      y: lastCardInFirstRow.position.y + (height / 2) - (stickyNoteHeight / 2)
    };

    const stickyNoteNode: Node = {
      id: 'stickyNote',
      type: 'stickyNote',
      position: stickyNotePosition,
      data: { text: stickyNoteText, equation: equationLatex },
      draggable: false,
    };

    const plugAndPlayButtonPosition = {
      x: lastCardInFirstRow.position.x + maxWidth + horizontalSpacing,
      y: lastCardInFirstRow.position.y + height*1.25,
    };

    const webinarButtonPosition = {
      x: plugAndPlayButtonPosition.x,
      y: plugAndPlayButtonPosition.y - height*0.35,
    };

    const calloutMessagePosition = {
      x: firstCard.position.x - maxWidth * 1.2,
      y: firstCard.position.y + maxWidth * 0.25,
    };

    // New position for the second sticky note
    const secondStickyNotePosition = {
      x: plugAndPlayButtonPosition.x,
      y: plugAndPlayButtonPosition.y + 75 + 20 // 75 is the height of the Plug and Play button, 20 is for spacing
    };

    const webinarCounterWidth = maxWidth * 3 + horizontalSpacing * 2;
    const webinarCounterHeight = 180;
    const webinarCounterPosition = {
      x: calculatedNodes[0].position.x,
      y: calculatedNodes[0].position.y - webinarCounterHeight - 30
    };

    return {
      nodes: calculatedNodes,
      stickyNoteNode,
      studentJourneyPosition,
      studentJourneyWidth,
      studentJourneyHeight,
      studentSuccessPosition,
      plugAndPlayButtonPosition,
      webinarButtonPosition,
      webinarCounterPosition,
      webinarCounterWidth,
      webinarCounterHeight,
      calloutMessagePosition,
      secondStickyNotePosition,
    };
  }, [windowWidth]);

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, onEdgesChange] = useEdgesState(initialEdges.map(edge => ({ ...edge, type: 'custom' })));
  const calculatedPositions = useMemo(() => calculateNodePositions(), [calculateNodePositions]);
  const [expandedNodeId, setExpandedNodeId] = useState<string | null>(null);

  const handleNodeExpand = useCallback((nodeId: string, expanded: boolean) => {
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === nodeId) {
          const newData = { ...n.data, expanded };
          onCardExpand(expanded ? n.data.label : null);
          return { ...n, data: newData, style: { ...n.style, zIndex: expanded ? 1000 : 1 } };
        } else if (expanded) {
          return { ...n, data: { ...n.data, expanded: false }, style: { ...n.style, zIndex: 1 } };
        }
        return n;
      })
    );
    setExpandedNodeId(expanded ? nodeId : null);
  }, [setNodes, onCardExpand]);

  const handleBackgroundClick = useCallback(() => {
    if (expandedNodeId) {
      handleNodeExpand(expandedNodeId, false);
    }
  }, [expandedNodeId, handleNodeExpand]);

  useEffect(() => {
    const { 
      nodes: newNodes,
      stickyNoteNode,
      studentJourneyPosition,
      studentJourneyWidth,
      studentJourneyHeight,
      studentSuccessPosition,
      plugAndPlayButtonPosition,
      webinarButtonPosition,
      webinarCounterPosition,
      webinarCounterWidth,
      webinarCounterHeight,
      calloutMessagePosition,
      secondStickyNotePosition,
    } = calculatedPositions;

    const studentJourneyNode: Node = {
      id: 'studentJourney',
      type: 'studentJourney',
      position: studentJourneyPosition,
      data: { label: 'I helped Claire get started', width: studentJourneyWidth, height: studentJourneyHeight, videoFinished },
      draggable: false,
    };


    const studentSuccessNode: Node = {
      id: 'studentSuccess',
      type: 'studentSuccess',
      position: studentSuccessPosition,
      data: { width: studentJourneyWidth },
      draggable: false,
    };

    const plugAndPlayButtonNode: Node = {
      id: 'plugAndPlayButton',
      type: 'plugAndPlayButton',
      position: plugAndPlayButtonPosition,
      data: { width: 310, height: 75 },
      draggable: false,
    };

    const webinarButtonNode: Node = {
      id: 'webinarButton',
      type: 'webinarButton',
      position: webinarButtonPosition,
      data: { width: 310, height: 75 },
      draggable: false,
    };

    const webinarCounterNode: Node = {
      id: 'webinarCounter',
      type: 'webinarCounter',
      position: webinarCounterPosition,
      data: { width: webinarCounterWidth, height: webinarCounterHeight },
      draggable: false,
    };

    const calloutMessageNode: Node = {
      id: 'calloutMessage',
      type: 'calloutMessage',
      position: calloutMessagePosition,
      data: { message: "Learn how to Master this Critical Step in the Live Class!", pointDirection: 'right' },
      draggable: false,
      style: { zIndex: 1000 },
    };

    const secondStickyNoteNode: Node = {
      id: 'secondStickyNote',
      type: 'stickyNote',
      position: secondStickyNotePosition,
      data: { 
        text: "This site was built with Cursor, technical capabilities for non-developers expand everyday",
        equation: ""
      },
      draggable: false,
    };

    const nodesWithExpandHandler = newNodes.map(node => ({
      ...node,
      data: { ...node.data, id: node.id, onExpand: handleNodeExpand },
    }));

    setNodes([
      ...nodesWithExpandHandler, 
      studentJourneyNode, 
      studentSuccessNode, 
      plugAndPlayButtonNode, 
      stickyNoteNode, 
      webinarButtonNode, 
      webinarCounterNode, 
      calloutMessageNode, 
      secondStickyNoteNode
    ]);

    if (windowWidth < 768) {
      const techDueDiligenceNode = newNodes.find(node => node.data.label === "Technical Due Diligence");

      if (techDueDiligenceNode) {
        const viewport: Viewport = { x: 0, y: 0, zoom: 1 };
        if (!isNaN(viewport.x) && !isNaN(viewport.y) && !isNaN(viewport.zoom)) {
          setViewport(viewport, { duration: 800 });
        }
      }
    }
    
    // Fit view after a short delay to ensure all nodes are rendered
    setTimeout(() => {
      fitView({ 
        padding: 0.1, 
        includeHiddenNodes: true,
        minZoom: 0.5,
        maxZoom: 1,
      });
    }, 100);

  }, [calculatedPositions, windowWidth, setNodes, setViewport, fitView, videoFinished, handleNodeExpand]);

  return (
    <div 
      style={{
        width: '100%',
        height: '100%',
        backgroundImage: 'url("/cork-board.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
      onClick={handleBackgroundClick}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange as OnEdgesChange}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitView={false}
        minZoom={0.5}
        maxZoom={1}
      >
        <Controls />
      </ReactFlow>
    </div>
  );
};

interface FlowDiagramProps {
  videoFinished: boolean;
  onCardExpand: (cardLabel: string | null) => void;
}

const FlowDiagram: React.FC<FlowDiagramProps> = ({ videoFinished, onCardExpand }) => {
  return (
    <ReactFlowProvider>
      <div style={{ width: '100%', height: '100vh' }}>
        <FlowDiagramContent onCardExpand={onCardExpand} videoFinished={videoFinished} />
        <style>
          {`
            .react-flow__pane {
              cursor: default !important;
            }
            @keyframes flowAnimation {
              0% {
                stroke-dasharray: 10;
                stroke-dashoffset: 0;
              }
              100% {
                stroke-dasharray: 10;
                stroke-dashoffset: -40;
              }
            }
          `}
        </style>
      </div>
    </ReactFlowProvider>
  );
};

export default FlowDiagram;
