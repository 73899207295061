import React from 'react';

import { motion } from 'framer-motion';

import C42Image from '../images/C42.jpg';



const ProfilePicture: React.FC = () => {

  return (

    <motion.div

      className="flex justify-center items-center"

      initial={{ scale: 0 }}

      animate={{ scale: 1 }}

      transition={{ duration: 0.5 }}

      style={{

        width: '115%',  // Increase this value to zoom out

        height: '115%', // Increase this value to zoom out

        position: 'absolute',

        top: '-2%',

        left: '-10%',

        transform: 'translate(-50%, -50%)',

      }}

    >

      <img

        src={C42Image}

        alt="Student Profile"

        style={{

          width: '100%',

          height: '100%',

          objectFit: 'cover',

          objectPosition: 'center',

        }}

      />

    </motion.div>

  );

};



export default ProfilePicture;


