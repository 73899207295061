import React from 'react';

interface DownloadButtonProps {
  fileUrl: string;
  fileName: string;
  buttonText: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ fileUrl, fileName, buttonText }) => {
  return (
    <a
      href={fileUrl}
      download={fileName}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
      {buttonText || `Download ${fileName}`}
    </a>
  );
};

export default DownloadButton;
