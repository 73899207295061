import React, { useEffect, useRef } from 'react';

// Add this declaration at the top of the file
declare global {
  interface Window {
    _wq: any[];
  }
}

interface WistiaModalProps {
  onVideoComplete: () => void;
}

const WistiaModal: React.FC<WistiaModalProps> = ({ onVideoComplete }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = 'https://fast.wistia.com/embed/medias/y5zsozxpr2.jsonp';
    script1.async = true;

    const script2 = document.createElement('script');
    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);

    script2.onload = () => {
      if (window._wq) {
        window._wq.push({
          id: 'y5zsozxpr2',
          onReady: (video: any) => {
            video.bind('end', onVideoComplete);
          },
        });
      }
    };

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, [onVideoComplete]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-3xl w-full">
        <div className="wistia_responsive_padding" style={{padding:'56.25% 0 0 0', position:'relative'}}>
          <div className="wistia_responsive_wrapper" style={{height:'100%', left:0, position:'absolute', top:0, width:'100%'}}>
            <div ref={containerRef} className="wistia_embed wistia_async_y5zsozxpr2 seo=true videoFoam=true" style={{height:'100%', position:'relative', width:'100%'}}>
              <div className="wistia_swatch" style={{height:'100%', left:0, opacity:0, overflow:'hidden', position:'absolute', top:0, transition:'opacity 200ms', width:'100%'}}>
                <img src="https://fast.wistia.com/embed/medias/y5zsozxpr2/swatch" style={{filter:'blur(5px)', height:'100%', objectFit:'contain', width:'100%'}} alt="" aria-hidden="true" onLoad={(e) => (e.currentTarget.parentNode as HTMLElement).style.opacity = '1'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WistiaModal;