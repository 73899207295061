import React, { useState, useEffect } from 'react';
import { NodeProps } from 'reactflow';

interface StudentWin {
  name: string;
  winDescription: string;
}

interface StudentSuccessData {
  width: number;
}

const studentWins: StudentWin[] = [
  { name: "Jared T.", winDescription: "Consistently booking calls from LinkedIn in the first month" },
  { name: "Fredrik J.", winDescription: "Case Study Completed - for real estate agent in the first month" },
  { name: "Josh J.", winDescription: "$10k deal booked within the first two weeks of the program" },
  { name: "Matt M.", winDescription: "Had 6 discovery calls booked in 3 days in the first month" },
  { name: "Cheryl L.", winDescription: "Secured $850/month retainer for a Book Publisher, in the first month" },
  { name: "Cheryl L.", winDescription: "Another $850/month retainer for Insurance company, in the first month" },
  { name: "Mike G.", winDescription: "His first project was a $950 automation" },
  { name: "Aron", winDescription: "Booked $2k build + $500/month maintenance" },
  { name: "Michael Z.", winDescription: "Closed a landscaping client within the first two weeks worth $2,200" },
  { name: "Nicho A.", winDescription: "Met goal of $20k/month, with a record-breaking month in March" },
];

const StudentSuccess: React.FC<NodeProps<StudentSuccessData>> = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % studentWins.length);
    }, 5000); // Change student win every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{
      width: `${data.width}px`,
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
    }}>
      <div style={{
        backgroundColor: '#ffde59',
        padding: '10px',
        textAlign: 'center',
      }}>
        <h2 style={{ 
          fontSize: '1.5rem', 
          margin: 0,
          color: 'black',
          fontWeight: 'bold',
        }}>Recent Feeble Wins</h2>
      </div>
      <div style={{ padding: '20px', textAlign: 'center' }}>
        <div style={{ fontSize: '1.2rem', marginBottom: '10px', fontWeight: 'bold' }}>
          {studentWins[currentIndex].name}
        </div>
        <div style={{ fontSize: '1rem', color: '#333', lineHeight: '1.4' }}>
          {studentWins[currentIndex].winDescription}
        </div>
      </div>
    </div>
  );
};

export default StudentSuccess;