import React from 'react';
import Countdown from 'react-countdown';
import noteBackground from '../images/note.png'; // Make sure this path is correct
import CalendarEvent from './CalendarEvent'; // Import the CalendarEvent component




interface WebinarCounterProps {



  data: {



    width: number;



    height: number;



  };



}







const WebinarCounter: React.FC<WebinarCounterProps> = ({ data }) => {



  // Function to get the next Wednesday at 7:30 PM EST



  const getNextWednesday = () => {



    const now = new Date();



    const nextWednesday = new Date(now);



    // Calculate the next Wednesday

    nextWednesday.setDate(now.getDate() + ((3 + 7 - now.getDay()) % 7));



    // If today is Wednesday and the time is past 7:30 PM, move to next Wednesday

    if (now.getDay() === 3 && (now.getHours() > 19 || (now.getHours() === 19 && now.getMinutes() >= 30))) {

        nextWednesday.setDate(nextWednesday.getDate() + 7);

    }



    nextWednesday.setHours(19, 30, 0, 0); // Set to 7:30 PM



    // Adjust for EST (UTC-5)

    const estOffset = -6 * 60; // EST is UTC-5

    const utcOffset = now.getTimezoneOffset(); // Get local timezone offset in minutes

    nextWednesday.setMinutes(nextWednesday.getMinutes() + estOffset + utcOffset);



    return nextWednesday;



  };







  // Renderer for the countdown



  const renderer = ({ days, hours, minutes, seconds, milliseconds, completed }: {



    days: number;



    hours: number;



    minutes: number;



    seconds: number;



    milliseconds: number;



    completed: boolean;



  }) => {



    if (completed) {



      return <span>Webinar is live!</span>;



    } else {



      const calendarEventHeight = 100; // Height of the CalendarEvent component
      const counterHeight = calendarEventHeight * 0.9; // 90% of the CalendarEvent height

      return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: 'calc(100% - 170px)', marginRight: '10px' }}>
            {[
              { value: days, label: 'DAYS' },
              { value: hours, label: 'HRS' },
              { value: minutes, label: 'MIN' },
              { value: seconds, label: 'SEC' },
              { value: Math.floor(milliseconds / 10), label: 'MS', isMilliseconds: true }
            ].map((item, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: index < 4 ? '10px' : '0' }}>
                <div style={{
                  width: item.isMilliseconds ? '100px' : '70px',
                  height: `${counterHeight}px`,
                  backgroundColor: '#2c2c2c',
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                  overflow: 'hidden',
                  marginRight: '5px'
                }}>
                  <div style={{
                    color: '#ffd700',
                    fontSize: '32px',
                    fontWeight: 'bold',
                    zIndex: 2
                  }}>
                    {item.value.toString().padStart(item.isMilliseconds ? 3 : 2, '0')}
                  </div>
                  <div style={{
                    position: 'absolute',
                    width: '100%',
                    height: '1px',
                    backgroundColor: 'rgba(255,255,255,0.2)',
                    top: '50%'
                  }}/>
                  <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    height: '50%',
                    backgroundColor: '#3c3c3c'
                  }}/>
                </div>
                <div style={{ 
                  fontSize: '22px',
                  color: '#000000', 
                  fontWeight: 'bold',
                  width: '40px',
                  textAlign: 'left'
                }}>
                  {item.label}
                </div>
              </div>
            ))}
          </div>
          <CalendarEvent data={{ width: 160, height: 100 }} />
        </div>
      );



    }



  };







  return (



    <div style={{ 



      width: `${data.width}px`, 



      height: `${data.height}px`,



      display: 'flex',



      flexDirection: 'column',



      justifyContent: 'center',



      alignItems: 'center',



      padding: '20px',



      backgroundImage: `url(${noteBackground})`,



      backgroundSize: '100% 200%',



      backgroundPosition: 'center bottom',



      borderRadius: '10px',



      color: '#333',



      fontFamily: 'Arial, sans-serif',



      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'



    }}>



      <div style={{ marginBottom: '6px', fontSize: '30px', fontWeight: 'bold' }}>LIVE CLASS STARTING IN...</div>



      <Countdown date={getNextWednesday()} renderer={renderer} intervalDelay={0} precision={3} />



    </div>



  );



};







export default WebinarCounter;








