import React from 'react';
import WistiaPlayer from '../components/WistiaPlayer';
import ImageDisplay from '../components/ImageDisplay';
import DownloadButton from '../components/DownloadButton';
import RssFeedMiroImage from '../images/RssFeed.Miro.jpg';

const ResourcesPage: React.FC = () => {
  console.log("ResourcesPage rendered");

  // Add this function to handle video completion
  const handleVideoComplete = () => {
    console.log("Video playback completed");
    // Add any additional logic you want to execute when the video finishes
  };

  return (
    <div className="container mx-auto px-4 py-8 text-center text-black min-h-screen">
      <h1 className="text-4xl font-bold mb-6">Plug and Play!</h1>
      
      <div className="mb-12 bg-white p-6 rounded-lg border-4 border-[#ffde59] shadow-[0_0_20px_rgba(255,222,89,0.3)]">
        <h2 className="text-3xl font-semibold mb-2">Generate Endless Social Media Content from a News Feed</h2>
        <p className="text-xl mb-4">Miro Boards are fantastic sales assets that communicate how different automations work</p>
        <ImageDisplay imageSrc={RssFeedMiroImage} altText="RSS Feed Miro Board" />
      </div>
      
      <div className="mb-12 bg-white p-6 rounded-lg border-4 border-[#ffde59] shadow-[0_0_20px_rgba(255,222,89,0.3)]">
        <h2 className="text-3xl font-semibold mb-2">Make Explanation</h2>
        <p className="text-xl mb-4">Watch the video to see how the different articles are summarized and then posted to the different social media platforms.</p>
        <WistiaPlayer videoId="y5zsozxpr2" onComplete={handleVideoComplete} />
      </div>
      
      <div className="bg-white p-10 rounded-lg border-4 border-[#ffde59] shadow-[0_0_20px_rgba(255,222,89,0.3)]">
        <h2 className="text-3xl font-semibold mb-6">Get the JSON file to kickstart your automation!</h2>
        <DownloadButton 
          fileUrl="/RSSFeedtoSocialMediaContent.json"
          fileName="RSSFeedtoSocialMediaContent.json"
          buttonText="Download Make Blueprint!"
        />
     
      </div>
    </div>
  );
};

export default ResourcesPage;
