import React from 'react';
import { Handle, Position } from 'reactflow';

interface CalloutMessageProps {
  data: {
    message: string;
    pointDirection: 'left' | 'right' | 'up' | 'down';
  };
}

const CalloutMessage: React.FC<CalloutMessageProps> = ({ data }) => {
  const { message, pointDirection } = data;

  const bubbleStyle: React.CSSProperties = {
    backgroundColor: 'rgba(255, 255, 221, 0.9)',
    border: '1px solid #ccc',
    borderRadius: '10px',
    padding: '15px',
    maxWidth: '300px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    fontFamily: '"Roboto", sans-serif',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    position: 'relative',
    color: 'black',
  };

  const arrowStyle: React.CSSProperties = {
    position: 'absolute',
    width: '0',
    height: '0',
    border: '15px solid transparent',
  };

  switch (pointDirection) {
    case 'right':
      arrowStyle.left = '100%';
      arrowStyle.top = '50%';
      arrowStyle.marginTop = '-15px';
      arrowStyle.borderLeftColor = 'rgba(255, 255, 221, 0.9)';
      break;
    case 'up':
      arrowStyle.bottom = '100%';
      arrowStyle.left = '50%';
      arrowStyle.marginLeft = '-15px';
      arrowStyle.borderBottomColor = 'rgba(255, 255, 221, 0.9)';
      break;
    // Add cases for 'left' and 'down' if needed
  }

  return (
    <>
      <div style={bubbleStyle}>
        {message}
        <div style={arrowStyle} />
      </div>
      <Handle type="source" position={Position.Right} style={{ visibility: 'hidden' }} />
    </>
  );
};

export default CalloutMessage;
