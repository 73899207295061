import React from 'react';

interface WebinarButtonProps {
  data: {
    width: number;
    height: number;
  };
}

const WebinarButton: React.FC<WebinarButtonProps> = ({ data }) => {
  const handleClick = () => {
    window.open('https://event.webinarjam.com/go/live/6/yxr5mbgc0s1sr', '_blank');
  };

  return (
    <div 
      className="flex items-center justify-center text-white rounded-lg cursor-pointer transition-colors duration-300"
      style={{ 
        width: `${data.width}px`, 
        height: `${data.height}px`,
        backgroundColor: '#1b75e5',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
      onClick={handleClick}
    >
      <span className="text-center font-bold">Join the Live Class!</span>
    </div>
  );
};

export default WebinarButton;
